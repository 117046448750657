<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="iq-privacy-policy">
          <div class="mb-3">
            <h4 class="mb-3">What is Sports Betting?</h4>
            <p class="my-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis
              nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis
              orci feugiat hendrerit. Morbi ullamcorper consequat justo, in
              posuere nisi efficitur sed. Vestibulum semper dolor id arcu
              finibus volutpat. Integer condimentum ex tellus, ac finibus metus
              sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit
              eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo
              vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non
              aliquet viverra, elementum eget lorem
            </p>
            <p class="my-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis
              nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis
              orci feugiat hendrerit. Morbi ullamcorper consequat justo, in
              posuere nisi efficitur sed. Vestibulum semper dolor id arcu
              finibus volutpat. Integer condimentum ex tellus, ac finibus metus
              sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit
              eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo
              vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non
              aliquet viverra, elementum eget lorem
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PrivacyPolicyDetail'
}
</script>
