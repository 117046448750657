<template>
      <main id="main" class="site-main">
        <SportsBettingDetail/>
    </main>
</template>
<script>
import SportsBettingDetail from './Components/Projects/SportsBettingDetail'
export default {
  name: 'PrivacyPolicy',
  components: {
    SportsBettingDetail
  }
}
</script>
